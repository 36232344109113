import { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/ListYourHotel.css";
import "./Css/CoprativeSolution.css";
import "./Css/Influencer.css";
import circleImgtop from "../Assets/circleImgtop.svg";
import InflucencerHeroRightImg from "../Assets/InflucencerHeroRightImg.png";
import chill_girl from "../Assets/chill_girl.svg";
import leftImgFormInfluncer from "../Assets/leftImgFormInfluncer.png";
import Clouds from "../Assets/cloudsBtm.png";
import Testimonial from "./Testimonial";
import Faq from "./Reapeting Componets/Faq";
import BookingLoader from "./BookingLoader.js";
import Footer from "./Footer";
import {
  server_post_data,
  save_forms_enquiry,
  get_website_faq,
} from "../ServiceConnection/serviceconnection";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleLinkClick,
  handleNumbersChange,
  ////handleSuccess,
} from "../CommonJquery/CommonJquery.js";
import SuccessFullModal from "./SuccessFullModal";
let click_time = 0;
export default function Influencer() {
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [facebookUsername, setFacebookUsername] = useState("");
  const [facebookFollowers, setFacebookFollowers] = useState("");
  const [instagramUsername, setInstagramUsername] = useState("");
  const [instagramFollowers, setInstagramFollowers] = useState("");
  const [xUsername, setXUsername] = useState("");
  const [xFollowers, setXFollowers] = useState("");
  const [youtubeUsername, setYoutubeUsername] = useState("");
  const [youtubeFollowers, setYoutubeFollowers] = useState("");
  const handleShowSucessModal = () => setShowSucessModal(true);
  const handleCloseSuccModal = () => setShowSucessModal(false);
  const increaseVisiData = [
    {
      title: "Get free stay",
      description:
        "Experience luxury stays at our partner hotels for free! Join our Influencer Program, and enjoy complimentary hotel accommodations in exchange for your content.",
    },
    {
      title: "Recurring/Monthly Income",
      description:
        "Partner with us and earn a steady monthly income! Share your referral link and get commissions for every booking made through your content.",
    },
    {
      title: "Special discount",
      description:
        "Unlock exclusive discounts on hotel stays just for you! As a partner, enjoy special rates and offers that you can pass on to your followers.",
    },
    {
      title: "Get visibility",
      description:
        "Boost your profile and reach a wider audience! Partner with us to gain visibility and feature your content on our platform.",
    },
  ];
  const steps = [
    {
      number: "01",
      title: "Sign-up process",
      description: "Complete our simple application form.",
    },

    {
      number: "02",
      title: "Get Approved",
      description: "Our team will review your application and get in touch.",
    },
    {
      number: "03",
      title: "Earn Rewards",
      description:
        "Get commissions for every booking made through your content.",
    },
  ];
  const valueItems = [
    {
      title: "01",
      description:
        "A public social media profile (Instagram, YouTube, Facebook, etc )",
    },
    {
      title: "02",
      description: "A strong following of at least 5,000 genuine followers",
    },
    {
      title: "03",
      description:
        "High-quality content focused on travel, lifestyle, or hospitality",
    },
  ];

  const [testinomial, settestinomial] = useState([]);
  const [faq, setfaq] = useState([]);
  const [testimonial_image_link, settestimonial_image_link] = useState("");
  // const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_website_faq, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          const final_data = JSON.parse(data1[1]);
          settestinomial(final_data.testimons);
          setfaq(final_data.faq_data);
          settestimonial_image_link(final_data.testimonial_image_link);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);
  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data && click_time === 0) {
      const requirementDescription =
        "Facebook UserName:- " +
        facebookUsername +
        " Follows:-" +
        facebookFollowers +
        " , instagram UserName:- " +
        instagramUsername +
        " Follows:-" +
        instagramFollowers +
        " , x UserName:- " +
        xUsername +
        " Follows:-" +
        xFollowers +
        " , youtube UserName:- " +
        youtubeUsername +
        " Follows:-" +
        youtubeFollowers;
      click_time = 1;
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("enquiry_type", "6");
      fd_from.append("requirementDescription", requirementDescription);
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          let data1 = Response.split("~@~");
          if (parseInt(data1[0]) === 1) {
            handleError(data1[1]);
          } else {
            click_time = 0;
            handleShowSucessModal();
            empty_form(form_data);
            setTimeout(() => {
              handleLinkClick("/");
            }, 2000);
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          click_time = 0;
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleActive = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPosition - 250,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <Header />
      {showLoaderAdmin && <BookingLoader />}
      <div className="contList">
        {" "}
        <div className="IncluncerHero ">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <div className="LEftInfluncer">
                  <h1>
                    {" "}
                    Partner with Us and{" "}
                    <span className="d-sm-none"> Earn as an Influencer!</span>
                  </h1>
                  <p className="d-sm-block d-none"> Earn as an Influencer!</p>
                  <label>
                    Book accommodations for corporate training, events, and
                    employee stays with exclusive benefits and customized
                    packages
                  </label>

                  <button
                    type="button"
                    className="DarkButtonClass JoinTHprogrm"
                    onClick={() => handleActive("formCOntainerCreate")}
                  >
                    Join The Program
                  </button>
                </div>
              </div>
              <div className="col-sm-6 m-auto">
                <div className="heroRIghtInfluncer">
                  {" "}
                  <img
                    src={InflucencerHeroRightImg}
                    alt="InflucencerHeroRightImg"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="container-lg">
          <div className="QuicklyHotl santosiFOntCLass">
            {" "}
            <p>Why Become an Influencer Partner</p>
            <div className="quickHotlsubhading ">
              {" "}
              <h2>
                Grow with us as a <span>Influencer program </span>
              </h2>
            </div>
          </div>

          <div className="increaseVisiImgCOnt ContnrCoprateStay">
            <div className="row">
              {increaseVisiData.map((item, index) => (
                <div className="col-md-6 col-sm-12" key={index}>
                  <div className="InfluncerPatnerDiv">
                    <div className="LeftBorderCl">
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/**----------How it work for hotel ------*/}
        </div>
        <div className="howItworkDivHotel">
          <div className="container-lg">
            <div className="QuicklyHotl santosiFOntCLass">
              {" "}
              <p>How it works</p>
              <div className="quickHotlsubhading">
                {" "}
                <h2>
                  How does the <span>influencer program work</span>
                </h2>
              </div>
            </div>
            <div className="howItWorksHotel__steps">
              <div className="row">
                {steps.map((step, index) => (
                  <div key={index} className="col-12 col-md-6 col-lg-4">
                    <div className="width80 coprtHowitwork">
                      <h1>{step.number}</h1>
                      <div className="horIzontlist"></div>
                      <h3>{step.title}</h3>
                      <p>{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/**------------- Ready To grow your Bussiness-------*/}
        <div className="ReadyBussiNessDiv position-relative   unlockedIncluncerDiv">
          <div className="container-lg">
            <div className="row ReadyDivContent">
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="leftReadyDiv">
                  <div className="readyHead  rightContInclucenrerUnlockEarning">
                    <h2>Unlock Earnings of Up to 10 Lakhs</h2>
                    <p>
                      Join our Influencer Program today and start earning big!
                      Share your unique referral link and earn up to 10 lakhs by
                      driving bookings through your content.
                    </p>
                  </div>

                  <button className="ListYourBssnssButton  ApplyNowBtnInflucr">
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 RIghtReadyDiv text-center mt-4 mt-md-0 bussinessRight">
                <img src={chill_girl} alt="property"></img>
              </div>
            </div>
          </div>
          <img src={Clouds} alt="Swagstay" className="cloudesImg" />
        </div>
        {/*-----our value--------- */}
        <div className="container-lg">
          <div className="QuicklyHotl col-8">
            <div className="quickHotlsubhading WHoJoinInfluencer">
              <p className="santosiFOntCLass"> Requirements Section</p>
              <div className="CopHotlSubHead gap-2 ">
                <h2>Who </h2>
                <span> can join?</span>
              </div>

              <label>
                We’re looking for passionate travel enthusiasts, bloggers,
                vloggers, and social media influencers who love to explore new
                destinations. to join, you should have:
              </label>
            </div>
          </div>
          <div className="row" style={{ position: "relative" }}>
            {valueItems.map((item, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 col-sm-12 marginclsrem"
              >
                <div className="boxcontant  InflucenerBox">
                  <img
                    className="infcicle"
                    src={circleImgtop}
                    alt="circleImgtop"
                  />
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*------Testimonial--- */}
        <Testimonial
          testinomial={testinomial}
          testimonial_image_link={testimonial_image_link}
        />
        {/*--------Ready to boost-----------*/}
        <div className="container-lg">
          <div className="QuicklyHotl">
            {" "}
            <p>Ready to Influence and Earn</p>
            <div className="quickHotlsubhading CopHotlSubHead">
              {" "}
              <h2>Become a partner and enjoy </h2>
              <span> exclusive rewards!</span>
            </div>
          </div>

          <div className="row listPropFOrm">
            {/* Left Column - Image and Heading */}
            <div className="col-md-4 col-12 mb-3 mb-md-0  FormLftdata  ">
              <img
                src={leftImgFormInfluncer}
                alt="Property Image"
                className="img-fluid"
              />
            </div>

            {/* Right Column - Form */}
            <div className="col-md-6 col-12" id="formCOntainerCreate">
              <form className="rightFOrm" id="listmyhotel">
                <div className="row mb-3">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="inputContainer">
                      <label htmlFor="fullName">Full name</label>
                      <input
                        type="text"
                        className="form-control trio_mandatory"
                        id="fullName"
                        name="fullName"
                        placeholder="Name"
                        onChange={handleAphabetsChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="inputContainer">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control trio_mandatory"
                        id="email"
                        name="email"
                        placeholder="abc@xyz.com"
                        onChange={handleEmailChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12  mb-3 mb-md-0">
                    <div className="inputContainer">
                      <label htmlFor="contactNumber">Contact number</label>
                      <input
                        type="text"
                        className="form-control trio_mandatory"
                        id="contactNumber"
                        name="contactNumber"
                        maxLength={10}
                        placeholder="+91-XXXXXXXXXX"
                        onChange={handleNumbersChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="inputContainer">
                    <label htmlFor="fullName">Social media handel</label>
                    <div className="socialInputContainer col-12">
                      {[
                        {
                          usernameId: "facebookUsername",
                          usernamePlaceholder: "Facebook Username",
                          onChangeUsername: setFacebookUsername,
                          followersId: "facebookFollowers",
                          followersPlaceholder: "Facebook Followers",
                          onChangeFollowers: setFacebookFollowers,
                        },
                        {
                          usernameId: "instagramUsername",
                          usernamePlaceholder: "Instagram Username",
                          onChangeUsername: setInstagramUsername,
                          followersId: "instagramFollowers",
                          followersPlaceholder: "Instagram Followers",
                          onChangeFollowers: setInstagramFollowers,
                        },
                        {
                          usernameId: "xUsername",
                          usernamePlaceholder: "X (Twitter) Username",
                          onChangeUsername: setXUsername,
                          followersId: "xFollowers",
                          followersPlaceholder: "X (Twitter) Followers",
                          onChangeFollowers: setXFollowers,
                        },
                        {
                          usernameId: "youtubeUsername",
                          usernamePlaceholder: "YouTube Username",
                          onChangeUsername: setYoutubeUsername,
                          followersId: "youtubeFollowers",
                          followersPlaceholder: "YouTube Followers",
                          onChangeFollowers: setYoutubeFollowers,
                        },
                      ].map((input, index) => (
                        <div key={index} className="col-12 displayflex">
                          <div
                            className={`socialIconinputCont${index + 1} col-6`}
                          >
                            <input
                              type="text"
                              className="form-control "
                              id={input.usernameId}
                              placeholder={input.usernamePlaceholder}
                              onChange={(e) =>
                                input.onChangeUsername(e.target.value)
                              }
                            />
                            <span className="condition_error"></span>
                          </div>
                          <div
                            className={`socialIconinputCont${index + 1} col-6`}
                          >
                            <input
                              type="text"
                              className="form-control "
                              id={input.followersId}
                              placeholder={input.followersPlaceholder}
                              onChange={(e) =>
                                input.onChangeFollowers(e.target.value)
                              }
                            />
                            <span className="condition_error"></span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <button
                  className="suFOrm fontSizeSUbdiv"
                  type="button"
                  onClick={() => {
                    handleSaveChangesdynamic("listmyhotel", save_forms_enquiry);
                  }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>{" "}
        {/*------Testimonial--- */}
        <div className="container-lg">
          <Faq faq={faq} />
        </div>
        <Footer />
      </div>

      <SuccessFullModal
        show={showSucessModal}
        handleCloseSuccModal={handleCloseSuccModal}
      />
    </>
  );
}
