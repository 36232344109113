import React, { useEffect, useState, useRef } from "react";
import "./Css/HomeHotelMapView.css";
import { useLocation, useNavigate } from "react-router-dom";
import SortByBar from "./SortByBar";
import Header from "./Header";
import city5 from "../Assets/city5.png";
import solar_tag from "../Assets/solar_tag.svg";
import geeenDot from "../Assets/geeenDot.svg";
import stockHeart from "../Assets/stockHeart.svg";
import filledHeart from "../Assets/HeartRed.svg";
import houseicn from "../Assets/houseicn.png";
import shereIcon from "../Assets/shereIcon.svg";
import hotelMarkerIcon from "../Assets/orngDOt.png";
import BackBtn from "../Assets/backIcon.svg";
import filterIconBlack from "../Assets/filterIconBlack.svg";
import whiteSTarrrr from "../Assets/whiteSTarrrr.svg";

import Shimmer from "./Shimmer";

import {
  search_list_type_hotels_more,
  add_remove_favorite_hotel,
  search_list_type_hotels,
  server_post_data,
  GOOGLE_PLACES_API_KEY,
} from "../ServiceConnection/serviceconnection";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import BackBar from "./Reapeting Componets/BackBar";
import "./Css/SharePopup.css";
import SharePopup from "./SharePopup";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  OverlayView,
} from "@react-google-maps/api";
let click_time = 0;
export default function HomeHotelMapView() {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationData = location.state?.data;
  const [selectedMarker, setSelectedMarker] = useState(null);
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const [HotelCategroyList, setHotelCategroyList] = useState([]);
  const [HotelFavouriteList, setHotelFavouriteList] = useState([]);
  const [CityID, setCityID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [SilderHotelImage, setSilderHotelImage] = useState("");
  const [ameniticsImage, setameniticsImage] = useState("");
  const [RupessIcon, setRupessIcon] = useState("");
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  const [SEOloop, setSEOloop] = useState([]);
  // filter code hook
  const [priceInRangemin, setpriceInRangemin] = useState(900);
  const [priceInRangemax, setpriceInRangemax] = useState(5000);
  const [Amenities, setAmenities] = useState("");
  const [matchesLocality, setmatchesLocality] = useState("");
  const [sortingdistance, setsortingdistance] = useState("");
  const [sortingprice, setsortingprice] = useState("");
  const [sortingrating, setsortingrating] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [FilterModalShow, setFilterModalShow] = useState(false);
  const defaultInitialCount = 5;
  const [initialCount, setInitialCount] = useState(defaultInitialCount);
  const itemsPerPage = 5;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const loaderRef = useRef(null); // Ref for the loading indicator

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + " ...";
    }
    return text;
  };

  const handleMarkerPress = (hotel) => {
    setSelectedMarker(hotel);
  };

  const openGoogleMaps = (latitude, longitude) => {
    window.open(
      `https://www.google.com/maps?q=${latitude},${longitude}`,
      "_blank"
    );
  };
  useEffect(() => {
    const updateInitialCount = () => {
      if (window.innerWidth <= 778) {
        setInitialCount(3); // Show only 3 items on small screens
      } else {
        setInitialCount(defaultInitialCount); // Default initial count on larger screens
      }
    };

    // Run on component mount and screen resize
    updateInitialCount();
    window.addEventListener("resize", updateInitialCount);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", updateInitialCount);
    };
  }, [defaultInitialCount]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          master_data_get_more();
        }
      },
      { threshold: 1.0 } // Trigger when the loader is fully visible
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [HotelCategroyList.length]);

  const HandleFilterModalSHow = () => {
    setFilterModalShow(!FilterModalShow);
  };
  const HandleCloseFilterModal = () => {
    setFilterModalShow(!FilterModalShow);
  };

  useEffect(() => {
    let city_id = navigationData.city_id;
    let url = navigationData.url_data;

    master_data_get(
      1,
      priceInRangemin,
      priceInRangemax,
      Amenities,
      matchesLocality,
      sortingdistance,
      sortingprice,
      sortingrating,
      city_id,
      url
    );
  }, []);

  const aqiCategories = [
    { min: 0, max: 50, label: "Good" },
    { min: 51, max: 100, label: "Satisfactory" },
    { min: 101, max: 200, label: "Moderate" },
    { min: 201, max: 300, label: "Poor" },
    { min: 301, max: 400, label: "Very Poor" },
    { min: 401, max: 500, label: "Severe" },
  ];

  const getAQICategory = (currentAQI) => {
    const category = aqiCategories.find(
      (category) => currentAQI >= category.min && currentAQI <= category.max
    );
    return category ? category.label : null; // Return null if no category is found
  };
  const ratingCategories = [
    { min: 1, max: 1.9, label: "Terrible" },
    { min: 2, max: 2.9, label: "Poor" },
    { min: 3, max: 3.9, label: "Average" },
    { min: 4, max: 4.9, label: "Good" },
    { min: 5, max: 5, label: "Excellent" },
  ];

  const getRatingCategory = (rating) => {
    const category = ratingCategories.find(
      (category) => rating >= category.min && rating <= category.max
    );
    return category ? category.label : null; // Return null if no category is found
  };

  const handleSaveChangesdynamic = async (hotel_id, HotelID) => {
    const form_data = new FormData();
    form_data.append("hotel_id", hotel_id);
    form_data.append("HotelID", HotelID);
    form_data.append("show_flag", "c");
    await server_post_data(add_remove_favorite_hotel, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setHotelFavouriteList(final_data.hotel_favourite);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const isFavorite = (hotel_id, HotelID) => {
    try {
      return HotelFavouriteList.some(
        (fav) => fav.hotel_id === hotel_id && fav.HotelID === HotelID
      );
    } catch (error) {
      return false;
    }
  };

  const master_data_get = async (
    click_from,
    priceInRangemindata,
    priceInRangemaxdata,
    amenitiesdata,
    matchesLocalitydata,
    sortingdistancedata,
    sortingpricedata,
    sortingratingdata,
    city_id,
    url
  ) => {
    console.log(city_id);
    if (click_time == 0) {
      click_time++;

      if (click_from == 1) {
        setShowLoaderAdmin(true);
      } else {
        setLoading(true);
      }

      const fd = new FormData();
      fd.append("next_limit", 0);
      fd.append("priceInRangemindata", priceInRangemindata);
      fd.append("priceInRangemaxdata", priceInRangemaxdata);
      fd.append("amenitiesdata", amenitiesdata);
      fd.append("matchesLocalitydata", matchesLocalitydata);
      fd.append("sortingdistancedata", sortingdistancedata);
      fd.append("sortingpricedata", sortingpricedata);
      fd.append("sortingratingdata", sortingratingdata);

      if (url === "search") {
        fd.append("custom_latitude", navigationData.latitude);
        fd.append("custom_longitude", navigationData.longitude);
        fd.append("search_keywords", navigationData.search_query);
        fd.append("search_keywords_city", navigationData.city_data);
        fd.append("city_id", 0);
        fd.append("by_web_search", "search_keywords");
      } else {
        fd.append("custom_latitude", 0);
        fd.append("custom_longitude", 0);
        fd.append("city_id", city_id);
        fd.append("custom_url", url);
      }
      fd.append("distance_call", 0);

      await server_post_data(search_list_type_hotels, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          click_time = 0;
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            setHotelCategroyList(final_data.hotel_list);
            setSilderHotelImage(final_data.hotel_image_link);
            setameniticsImage(final_data.hotel_amenitics_link);
            setRupessIcon(final_data.rupess_icon);
            setHotelFavouriteList(final_data.hotel_favourite);
            setCityID(final_data.city_id);
            setSEOloop(final_data.seo_list);
          }
          if (click_from == 1) {
            setShowLoaderAdmin(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          if (click_from == 1) {
            setShowLoaderAdmin(false);
          } else {
            setLoading(false);
          }
          click_time = 0;
        });
    }
  };

  const master_data_get_more = async () => {
    if (click_time == 0) {
      click_time++;
      setLoading(true);

      const fd = new FormData();
      // fd.append('search_keywords', searchText);
      fd.append("next_limit", HotelCategroyList.length);
      fd.append("priceInRangemindata", priceInRangemin);
      fd.append("priceInRangemaxdata", priceInRangemax);
      fd.append("amenitiesdata", Amenities);
      fd.append("matchesLocalitydata", matchesLocality);
      fd.append("sortingdistancedata", sortingdistance);
      fd.append("sortingpricedata", sortingprice);
      fd.append("sortingratingdata", sortingrating);
      if (navigationData.url_data === "search") {
        fd.append("custom_latitude", navigationData.latitude);
        fd.append("custom_longitude", navigationData.longitude);
        fd.append("search_keywords", navigationData.search_query);
        fd.append("search_keywords_city", navigationData.city_data);
        fd.append("city_id", 0);
        fd.append("by_web_search", "search_keywords");
      } else {
        fd.append("custom_latitude", 0);
        fd.append("custom_longitude", 0);
        fd.append("city_id", CityID);
        fd.append("custom_url", navigationData.url_data);
      }
      fd.append("distance_call", 0);
      await server_post_data(search_list_type_hotels_more, fd)
        .then((Response) => {
          setLoading(false);
          console.log(Response);
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            const HotelCategroyListFlag = final_data.hotel_list;
            setHotelCategroyList((prevData) => [
              ...prevData,
              ...HotelCategroyListFlag,
            ]);
          }
          click_time = 0;
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          click_time = 0;
        });
    }
  };

  const handlebookclick = (hotel_id, HotelID) => {
    let link_url = match_and_return_seo_link(HotelID, hotel_id);
    handleLinkClick("/" + link_url);
  };

  const match_and_return_seo_link = (HotelID, hotel_id) => {
    let data_seo_link_final = "hotel_detail/" + HotelID + "/" + hotel_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const initialRegion =
    HotelCategroyList?.length > 0
      ? {
          latitude: parseFloat(HotelCategroyList[0].latitude) || 23.2599,
          longitude: parseFloat(HotelCategroyList[0].longitude) || 77.4126,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }
      : {
          latitude: 23.2599,
          longitude: 77.4126,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        };
  const handleFilters1 = (filterData) => {
    const sortingdistancedata = filterData.sorting.distance;
    const sortingpricedata = filterData.sorting.price;
    const sortingratingdata = filterData.sorting.rating;
    setsortingdistance(sortingdistancedata);
    setsortingprice(sortingpricedata);
    setsortingrating(sortingratingdata);

    master_data_get(
      2,
      priceInRangemin,
      priceInRangemax,
      Amenities,
      matchesLocality,
      sortingdistancedata,
      sortingpricedata,
      sortingratingdata,
      CityID,
      currentUrl
    );
  };

  const PermanentMarkerWithRate = ({ hotel }) => {
    const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
    const getPixelPositionOffset = (width, height) => ({
      x: -(width / 2),
      y: -height,
    });
    const handleMarkerClick = () => {
      console.log("dddd");
      // Toggle InfoWindow
      setIsInfoWindowOpen(!isInfoWindowOpen);
    };

    const handleInfoWindowClose = () => {
      setIsInfoWindowOpen(false);
    };

    const navigateToHotelDetails = () => {
      handlebookclick(hotel.hotel_id, hotel.HotelID);
    };
    return (
      <>
        <React.Fragment key={hotel.id}>
          <Marker
            position={{
              lat: parseFloat(hotel.latitude),
              lng: parseFloat(hotel.longitude),
            }}
            icon={{
              url: hotelMarkerIcon,
              scaledSize: new window.google.maps.Size(40, 40),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(20, 40),
            }}
            onClick={handleMarkerClick}
          >
            {isInfoWindowOpen && (
              <InfoWindow onCloseClick={handleInfoWindowClose}>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <h5
                    style={{
                      color: "#181818",
                      margin: "0 0 10px 0",
                      textAlign: "left",
                    }}
                  >
                    {hotel.hotel_name}
                  </h5>
                  <p
                    style={{
                      margin: "5px 0",
                      color: "#666",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Price:
                    {parseInt(hotel.discount) > 0 ? (
                      <>
                        {RupessIcon}
                        {hotel.discount_price}
                      </>
                    ) : (
                      <>
                        {RupessIcon}
                        {hotel.lowest_price}
                      </>
                    )}
                  </p>
                  <button
                    onClick={navigateToHotelDetails}
                    style={{
                      marginTop: "10px",
                      padding: "10px 16px",
                      backgroundColor: "#f86855",
                      color: "white",
                      border: "none",
                      borderRadius: "20px",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    View Details
                  </button>
                </div>
              </InfoWindow>
            )}
          </Marker>

          <OverlayView
            position={{
              lat: parseFloat(hotel.latitude),
              lng: parseFloat(hotel.longitude),
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <div
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "2px 6px",
                borderRadius: "12px",
                fontSize: "15px",
                position: "absolute",
                top: "-58px",
                left: "-28px",
                fontWeight: "bold",
                border: "1px solid #ddd",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              {parseInt(hotel.discount) > 0 ? (
                <>
                  {RupessIcon}
                  {hotel.discount_price}
                </>
              ) : (
                <>
                  {RupessIcon}
                  {hotel.lowest_price}
                </>
              )}
            </div>
          </OverlayView>
        </React.Fragment>
      </>
    );
  };
  return (
    <div>
      <Header />
      {showLoaderAdmin && <Shimmer />}
      <SortByBar handleFilters1={(e) => handleFilters1(e)} />

      <div className="fixedDivHeght">
        <div className="mrginIGHt">
          <div className="row rowRvrs  topMargnDvvv m-0 mt-md-3">
            {/**-------------HotelList Data-------------- */}
            <div className="col-xl-5 col-md-6 hotelRightSection leftDataHtlData viwMpview">
              <div className="locationHotelNameHead locationHotelNameHead2">
                <img
                  className="houseICOnImg"
                  src={houseicn}
                  alt="houseicn"
                ></img>
                <h1>{HotelCategroyList.length} Properties found</h1>
              </div>

              {HotelCategroyList &&
                HotelCategroyList.length > 0 &&
                HotelCategroyList.map((hotel, index) => {
                  const hotelCreationDate = new Date(hotel.creation_date); // Convert creation_date to Date
                  const currentDate = new Date(); // Current date
                  const differenceInTime = currentDate - hotelCreationDate; // Difference in milliseconds
                  // Calculate difference in days
                  const differenceInDays =
                    differenceInTime / (1000 * 3600 * 24); // Convert milliseconds to days
                  let new_old = false;
                  if (differenceInDays <= 60) {
                    new_old = true;
                  }
                  return (
                    <div
                      key={index}
                      className="cardNewDiv mb-3"
                      onClick={() =>
                        handlebookclick(hotel.hotel_id, hotel.HotelID)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="mapddedCard">
                        <div className="hottleLeftDDtaa  MapViewHotelData">
                          {(hotel.sold_out === "1" || new_old) && (
                            <div
                              className={
                                hotel.sold_out === "1"
                                  ? "SoldOut"
                                  : new_old
                                  ? "newLounchDIv MapViewDiv"
                                  : ""
                              }
                            >
                              <p>
                                {hotel.sold_out === "1"
                                  ? "Sold Out"
                                  : new_old
                                  ? "New Launch"
                                  : ""}
                              </p>
                            </div>
                          )}
                          <div className="HEartDivAbs HotlMapViewDIv">
                            <img
                              src={
                                isFavorite(hotel.hotel_id, hotel.HotelID)
                                  ? filledHeart
                                  : stockHeart
                              }
                              alt="heart icon"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  hotel.hotel_id,
                                  hotel.HotelID
                                )
                              }
                            />
                          </div>
                          <img
                            className="hotlimglef2"
                            src={`${SilderHotelImage}${hotel.image_name}`}
                            alt="Room Photos"
                          />
                        </div>
                        <div className="topDivHotelContrr MapViewtopDivHotelContrr">
                          <div className="leftCOntnetTOp mb-0">
                            <div className="rttingCOntrDiv HotelRattingDiv">
                              <div className="lefRightDataRtting">
                                <div className="ratting MpSecRattingDiv">
                                  <img
                                    className="numbrRttingICn"
                                    src={whiteSTarrrr}
                                    alt="whiteSTarrrr"
                                  ></img>
                                  <span>{hotel.total_rating}</span>
                                </div>
                              </div>
                              <img
                                src={shereIcon}
                                alt="share icon"
                                onClick={() => {
                                  setisSharePopupContent(
                                    `Hey! 🌟 I just found this amazing hotel: ${hotel.hotel_name}.\n\nIf you're looking for a comfortable stay, check it out! They offer great amenities.\n\n📍 Location: ${hotel.address}\n⭐ Rating: ${hotel.total_rating}`
                                  );
                                  setSharePopupOpen(true);
                                }}
                              />
                            </div>

                            <div className="primeLocDIv">
                              {parseInt(hotel.on_prime_status) === 1 ? (
                                <div className="leftLocDiv">
                                  <img src={solar_tag} alt="solar_tag" />
                                  <p> On Prime Location</p>
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="RightLocDiv">
                                <img src={geeenDot} alt="solar_tag"></img>
                                <p>
                                  {" "}
                                  AQI {hotel.air_quality}
                                  <span className="dot">•</span>{" "}
                                  {getAQICategory(hotel.air_quality)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="middleDataHotelCOntnr">
                            <div className="hottlMiddleData MapViwMiddleData">
                              <h1 className="hideaFTER400">
                                {truncateText(hotel.hotel_name, 3)}
                              </h1>
                              <h1 className="SHOWaFTER400">
                                {truncateText(hotel.hotel_name, 2)}
                              </h1>
                              <p>{truncateText(hotel.address, 3)}</p>
                            </div>
                          </div>
                          <div className="rightSIdeData mapViewHotlDataBttm d-sm-flex d-none">
                            <div className="rightSIdeData">
                              <div className="RIghtData">
                                <p>Starting</p>
                                <div className="underataRight">
                                  {parseInt(hotel.discount) > 0 ? (
                                    <>
                                      <p>{hotel.discount}% off</p>
                                      <h2>
                                        {RupessIcon}
                                        {hotel.lowest_price}
                                      </h2>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="bokkNowwBtn">
                                <h1>
                                  {parseInt(hotel.discount) > 0 ? (
                                    <>
                                      {RupessIcon}
                                      {hotel.discount_price}
                                    </>
                                  ) : (
                                    <>
                                      {RupessIcon}
                                      {hotel.lowest_price}
                                    </>
                                  )}
                                </h1>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handlebookclick(
                                      hotel.hotel_id,
                                      hotel.HotelID
                                    )
                                  }
                                >
                                  Book Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-sm-none d-flex w-100"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="rightSIdeData mapViewHotlDataBttm">
                          <div className="rightSIdeData">
                            <div className="RIghtData">
                              <p>Starting</p>
                              <div className="underataRight">
                                {parseInt(hotel.discount) > 0 ? (
                                  <>
                                    <p>{hotel.discount}% off</p>
                                    <h2>
                                      {RupessIcon}
                                      {hotel.lowest_price}
                                    </h2>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="bokkNowwBtn">
                              <h1>
                                {parseInt(hotel.discount) > 0 ? (
                                  <>
                                    {RupessIcon}
                                    {hotel.discount_price}
                                  </>
                                ) : (
                                  <>
                                    {RupessIcon}
                                    {hotel.lowest_price}
                                  </>
                                )}
                              </h1>
                              <button type="button">Book Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {visibleItems < HotelCategroyList.length && (
                <div
                  ref={loaderRef}
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  Loading...
                </div>
              )}
              <SharePopup
                isOpen={isSharePopupOpen}
                onRequestClose={() => setSharePopupOpen(false)}
                Content={isSharePopupContent}
              />
            </div>
            {/**-------------Map Data-------------- */}
            <div className="col-xl-7 col-md-6 mapSection">
              <LoadScript googleMapsApiKey={GOOGLE_PLACES_API_KEY}>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: initialRegion.latitude,
                    lng: initialRegion.longitude,
                  }}
                  zoom={initialRegion.latitudeDelta * 145}
                >
                  {HotelCategroyList?.map((hotel, index) => (
                    <PermanentMarkerWithRate key={index} hotel={hotel} />
                  ))}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
