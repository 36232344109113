import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import SortByBar from "./SortByBar";
import "./Css/HotelListLocation.css";
import solar_tag from "../Assets/solar_tag.svg";
import geeenDot from "../Assets/geeenDot.svg";
import shereIcon from "../Assets/shereIcon.svg";
import stockHeart from "../Assets/stockHeart.svg";
import filledHeart from "../Assets/HeartRed.svg";
import Starrr from "../Assets/starrrrrr.png";
import whatIconWhite from "../Assets/chatUsIcon.png";
import houseicn from "../Assets/houseicn.png";
import { useLocation } from "react-router-dom";
import hotelQr2 from "../Assets/hotelQr2.png";
import filterIcon from "../Assets/filterICon.svg";
import FilterModal from "./FilterModal";
import "./Css/SharePopup.css";
import SharePopup from "./SharePopup";
import Shimmer from "./Shimmer";
import {
  search_list_type_hotels_more,
  add_remove_favorite_hotel,
  search_list_type_hotels,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
import Filter from "./Filter";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import { useSearchParams } from "react-router-dom";
let click_time = 0;
export default function HotelListLocation() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(true);
  const [HotelCategroyList, setHotelCategroyList] = useState([]);
  const [HotelFavouriteList, setHotelFavouriteList] = useState([]);
  const [CityID, setCityID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [SilderHotelImage, setSilderHotelImage] = useState("");
  const [ameniticsImage, setameniticsImage] = useState("");
  const [RupessIcon, setRupessIcon] = useState("");
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get("location") || "";
  const latitude = searchParams.get("latitude") || "";
  const longitude = searchParams.get("longitude") || "";
  const city_data = searchParams.get("city_data") || "";
  // filter code hook
  const [priceInRangemin, setpriceInRangemin] = useState(100);
  const [priceInRangemax, setpriceInRangemax] = useState(5000);
  const [Amenities, setAmenities] = useState("");
  const [matchesLocality, setmatchesLocality] = useState("");
  const [sortingdistance, setsortingdistance] = useState("");
  const [sortingprice, setsortingprice] = useState("");
  const [sortingrating, setsortingrating] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [FilterModalShow, setFilterModalShow] = useState(false);
  const defaultInitialCount = 5;
  const [initialCount, setInitialCount] = useState(defaultInitialCount);
  const itemsPerPage = 5;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const [SEOloop, setSEOloop] = useState([]);
  const [TotalHotels, setTotalHotels] = useState(0);
  const loaderRef = useRef(null); // Ref for the loading indicator

  useEffect(() => {
    const updateInitialCount = () => {
      if (window.innerWidth <= 778) {
        setInitialCount(3); // Show only 3 items on small screens
      } else {
        setInitialCount(defaultInitialCount); // Default initial count on larger screens
      }
    };

    // Run on component mount and screen resize
    updateInitialCount();
    window.addEventListener("resize", updateInitialCount);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", updateInitialCount);
    };
  }, [defaultInitialCount]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          master_data_get_more();
        }
      },
      { threshold: 1.0 } // Trigger when the loader is fully visible
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [HotelCategroyList.length]);

  const HandleFilterModalSHow = () => {
    setFilterModalShow(!FilterModalShow);
  };
  const HandleCloseFilterModal = () => {
    setFilterModalShow(!FilterModalShow);
  };

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    let city_id = "0";
    if (parts.length === 2) {
      city_id = parts[1];
    }

    master_data_get(
      1,
      priceInRangemin,
      priceInRangemax,
      Amenities,
      matchesLocality,
      sortingdistance,
      sortingprice,
      sortingrating,
      city_id,
      url
    );
  }, []);

  const HandleNavigate = () => {
    navigate("/home_hotel_map_view", {
      state: {
        data: {
          city_id: CityID,
          search_query: searchQuery,
          latitude: latitude,
          longitude: longitude,
          city_data: city_data,
          url_data: currentUrl,
        },
      },
    });
  };

  const aqiCategories = [
    { min: 0, max: 50, label: "Good" },
    { min: 51, max: 100, label: "Satisfactory" },
    { min: 101, max: 200, label: "Moderate" },
    { min: 201, max: 300, label: "Poor" },
    { min: 301, max: 400, label: "Very Poor" },
    { min: 401, max: 500, label: "Severe" },
  ];

  const getAQICategory = (currentAQI) => {
    const category = aqiCategories.find(
      (category) => currentAQI >= category.min && currentAQI <= category.max
    );
    return category ? category.label : null; // Return null if no category is found
  };
  const ratingCategories = [
    { min: 1, max: 1.9, label: "Terrible" },
    { min: 2, max: 2.9, label: "Poor" },
    { min: 3, max: 3.9, label: "Average" },
    { min: 4, max: 4.9, label: "Good" },
    { min: 5, max: 5, label: "Excellent" },
  ];

  const getRatingCategory = (rating) => {
    const category = ratingCategories.find(
      (category) => rating >= category.min && rating <= category.max
    );
    return category ? category.label : null; // Return null if no category is found
  };

  const handleSaveChangesdynamic = async (hotel_id, HotelID) => {
    const form_data = new FormData();
    form_data.append("hotel_id", hotel_id);
    form_data.append("HotelID", HotelID);
    form_data.append("show_flag", "c");
    await server_post_data(add_remove_favorite_hotel, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setHotelFavouriteList(final_data.hotel_favourite);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const isFavorite = (hotel_id, HotelID) => {
    try {
      return HotelFavouriteList.some(
        (fav) => fav.hotel_id === hotel_id && fav.HotelID === HotelID
      );
    } catch (error) {
      return false;
    }
  };

  const master_data_get = async (
    click_from,
    priceInRangemindata,
    priceInRangemaxdata,
    amenitiesdata,
    matchesLocalitydata,
    sortingdistancedata,
    sortingpricedata,
    sortingratingdata,
    city_id,
    url
  ) => {
    if (click_time == 0) {
      click_time++;

      if (click_from == 1) {
        setShowLoaderAdmin(true);
      } else {
        setLoading(true);
      }
      console.log(url);

      const fd = new FormData();
      fd.append("next_limit", 0);
      fd.append("priceInRangemindata", priceInRangemindata);
      fd.append("priceInRangemaxdata", priceInRangemaxdata);
      fd.append("amenitiesdata", amenitiesdata);
      fd.append("matchesLocalitydata", matchesLocalitydata);
      fd.append("sortingdistancedata", sortingdistancedata);
      fd.append("sortingpricedata", sortingpricedata);
      fd.append("sortingratingdata", sortingratingdata);
      if (url === "search") {
        fd.append("custom_latitude", latitude);
        fd.append("custom_longitude", longitude);
        fd.append("search_keywords", searchQuery);
        fd.append("search_keywords_city", city_data);
        fd.append("city_id", 0);
        fd.append("by_web_search", "search_keywords");
      } else {
        fd.append("custom_latitude", 0);
        fd.append("custom_longitude", 0);
        fd.append("city_id", city_id);
        fd.append("custom_url", url);
      }

      fd.append("distance_call", 0);
      await server_post_data(search_list_type_hotels, fd)
        .then((Response) => {
          let data = Response.split("~@~");
          click_time = 0;
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            setHotelCategroyList(final_data.hotel_list);
            setSilderHotelImage(final_data.hotel_image_link);
            setameniticsImage(final_data.hotel_amenitics_link);
            setRupessIcon(final_data.rupess_icon);
            setHotelFavouriteList(final_data.hotel_favourite);
            setCityID(final_data.city_id);
            setSEOloop(final_data.seo_list);
            setTotalHotels(final_data.total_hotels);
          }
          if (click_from == 1) {
            setShowLoaderAdmin(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          if (click_from == 1) {
            setShowLoaderAdmin(false);
          } else {
            setLoading(false);
          }
          click_time = 0;
        });
    }
  };

  const master_data_get_more = async () => {
    if (click_time == 0) {
      click_time++;
      setLoading(true);

      const fd = new FormData();
      // fd.append('search_keywords', searchText);
      fd.append("next_limit", HotelCategroyList.length);
      fd.append("priceInRangemindata", priceInRangemin);
      fd.append("priceInRangemaxdata", priceInRangemax);
      fd.append("amenitiesdata", Amenities);
      fd.append("matchesLocalitydata", matchesLocality);
      fd.append("sortingdistancedata", sortingdistance);
      fd.append("sortingpricedata", sortingprice);
      fd.append("sortingratingdata", sortingrating);
      if (currentUrl === "search") {
        fd.append("custom_latitude", latitude);
        fd.append("custom_longitude", longitude);
        fd.append("search_keywords", searchQuery);
        fd.append("search_keywords_city", city_data);
        fd.append("city_id", 0);
        fd.append("by_web_search", "search_keywords");
      } else {
        fd.append("custom_latitude", 0);
        fd.append("custom_longitude", 0);
        fd.append("city_id", CityID);
        fd.append("custom_url", currentUrl);
      }
      fd.append("distance_call", 0);
      await server_post_data(search_list_type_hotels_more, fd)
        .then((Response) => {
          setLoading(false);
          let data = Response.split("~@~");
          if (parseInt(data[0]) === 1) {
            handleError(data[1]);
          } else {
            let final_data = JSON.parse(data[1]);
            const HotelCategroyListFlag = final_data.hotel_list;
            setHotelCategroyList((prevData) => [
              ...prevData,
              ...HotelCategroyListFlag,
            ]);
          }
          click_time = 0;
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          click_time = 0;
        });
    }
  };

  const handleFilters = (filterData) => {
    const priceInRangemindata = filterData.priceRange.min;
    const priceInRangemaxdata = filterData.priceRange.max;
    const Amenitiesdata = filterData.amenities;
    const matchesLocalitydata = filterData.locality.toLowerCase();

    setAmenities(Amenitiesdata);
    setpriceInRangemin(priceInRangemindata);
    setpriceInRangemax(priceInRangemaxdata);
    setmatchesLocality(matchesLocalitydata);
    master_data_get(
      2,
      priceInRangemindata,
      priceInRangemaxdata,
      Amenitiesdata,
      matchesLocalitydata,
      sortingdistance,
      sortingprice,
      sortingrating,
      CityID,
      currentUrl
    );
  };
  const handleFilters1 = (filterData) => {
    const sortingdistancedata = filterData.sorting.distance;
    const sortingpricedata = filterData.sorting.price;
    const sortingratingdata = filterData.sorting.rating;
    setsortingdistance(sortingdistancedata);
    setsortingprice(sortingpricedata);
    setsortingrating(sortingratingdata);
    master_data_get(
      2,
      priceInRangemin,
      priceInRangemax,
      Amenities,
      matchesLocality,
      sortingdistancedata,
      sortingpricedata,
      sortingratingdata,
      CityID,
      currentUrl
    );
  };

  const handlebookclick = (hotel_id, HotelID) => {
    let link_url = match_and_return_seo_link(HotelID, hotel_id);
    handleLinkClick("/" + link_url);
  };

  const match_and_return_seo_link = (HotelID, hotel_id) => {
    let data_seo_link_final = "hotel_detail/" + HotelID + "/" + hotel_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  return (
    <div>
      <Header />
      {showLoaderAdmin && <Shimmer />}
      <SortByBar handleFilters1={(e) => handleFilters1(e)} />
      <div className="container-xl HotlLIstFilterDiv">
        <div className="row m-0">
          <div className="col-lg-4 filterLeftSection filterSection991px">
            <div className="mapBg">
              <button type="button" onClick={HandleNavigate}>
                {" "}
                Show on map view{" "}
              </button>
            </div>

            <div className="d-lg-block d-none">
              <Filter
                onApplyFilters={(e) => handleFilters(e)}
                priceInRangemin={priceInRangemin}
                priceInRangemax={priceInRangemax}
                Amenities={Amenities}
                matchesLocality={matchesLocality}
              />
            </div>
            {/* Filter Button */}
            <div
              className="d-lg-none d-flex filterIconButton"
              onClick={HandleFilterModalSHow}
            >
              <img src={filterIcon} alt="Swagstay" />
            </div>
          </div>
          <div className="col-lg-8 hotelRightSection">
            <div className="locationHotelNameHead">
              <img
                className="houseICOnImg"
                style={{ marginBottom: "8px" }}
                src={houseicn}
                alt="houseicn"
              ></img>
              <h1 className="mb-2">
                {TotalHotels > HotelCategroyList.length
                  ? TotalHotels
                  : HotelCategroyList.length}{" "}
                Properties found
              </h1>
            </div>

            {HotelCategroyList &&
              HotelCategroyList.length > 0 &&
              HotelCategroyList.map((hotel, index) => {
                const hotelCreationDate = new Date(hotel.creation_date); // Convert creation_date to Date
                const currentDate = new Date(); // Current date
                const differenceInTime = currentDate - hotelCreationDate; // Difference in milliseconds
                // Calculate difference in days
                const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert milliseconds to days
                let new_old = false;
                if (differenceInDays <= 60) {
                  new_old = true;
                }
                return (
                  <div
                    key={index}
                    style={{ cursor: "pointer" }}
                    className={`${hotel.sold_out === "1" ? "soldoutdiv" : ""}`}
                    onClick={() =>
                      handlebookclick(hotel.hotel_id, hotel.HotelID)
                    }
                  >
                    <div className="cardStyle mb-4">
                      <div className="row m-0" aria-disabled>
                        <div className="col-12 col-sm-4 p-0">
                          <div className="hottleLeftDDtaa">
                            <div
                              className={
                                hotel.sold_out === "1" && new_old
                                  ? "SoldOut"
                                  : hotel.sold_out === "1"
                                  ? "SoldOut"
                                  : new_old
                                  ? "newLounchDIv"
                                  : "hidden"
                              }
                            >
                              <p>
                                {hotel.sold_out === "1" && new_old
                                  ? "Sold Out"
                                  : hotel.sold_out === "1"
                                  ? "Sold Out"
                                  : new_old
                                  ? "New Launch"
                                  : ""}
                              </p>
                            </div>
                            <div className="HEartDivAbs">
                              <img
                                src={
                                  isFavorite(hotel.hotel_id, hotel.HotelID)
                                    ? filledHeart
                                    : stockHeart
                                }
                                alt="heart icon"
                                onClick={() =>
                                  handleSaveChangesdynamic(
                                    hotel.hotel_id,
                                    hotel.HotelID
                                  )
                                }
                              />
                            </div>
                            <img
                              className="hotlimglef"
                              src={`${SilderHotelImage}${hotel.image_name}`}
                              alt="Room Photos"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm p-0">
                          <div className="topDivHotelContrr">
                            <div className="leftCOntnetTOp">
                              <div className="lefUnderData">
                                <div className="onprimeLoc">
                                  {parseInt(hotel.on_prime_status) === 1 ? (
                                    <>
                                      <img src={solar_tag} alt="solar_tag" />
                                      <p> On Prime Location</p>
                                      <div className="vertical-line"></div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="aqlImgDIv">
                                  <img src={geeenDot} alt="AQI indicator" />
                                  <p>
                                    AQI {hotel.air_quality}
                                    <span className="dot">•</span>{" "}
                                    {getAQICategory(hotel.air_quality)}
                                  </p>
                                </div>
                              </div>
                              <div className="rttingCOntrDiv">
                                <div className="lefRightDataRtting">
                                  <div className="ratting">
                                    <img
                                      src={Starrr}
                                      className="starrrIng"
                                      alt="Swagstay Star"
                                    />
                                    <span>{hotel.total_rating}</span>
                                  </div>
                                  <div className="ratting2">
                                    <span>Rating {hotel.total_rating}/5</span>
                                  </div>
                                  <div className="ratting3">
                                    <span>
                                      {getRatingCategory(hotel.total_rating)}
                                    </span>
                                  </div>
                                </div>
                                <img
                                  src={shereIcon}
                                  alt="share icon"
                                  onClick={() => {
                                    setisSharePopupContent(
                                      `Hey! 🌟 I just found this amazing hotel: ${hotel.hotel_name}.\n\nIf you're looking for a comfortable stay, check it out! They offer great amenities.\n\n📍 Location: ${hotel.address}\n⭐ Rating: ${hotel.total_rating}`
                                    );
                                    setSharePopupOpen(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middleDataHotelCOntnr">
                              <div className="hottlMiddleData">
                                <h1 className="eclipseText">
                                  {hotel.hotel_name}
                                </h1>
                                <p className="eclipseText">{hotel.address}</p>
                              </div>
                            </div>
                            {hotel.animintes_list &&
                              hotel.animintes_list.length > 0 && (
                                <div className="lastTopContr">
                                  <div className="hottlMiddleData2">
                                    <h2>Popular facilities</h2>
                                    <div className="LastopData">
                                      {hotel.animintes_list
                                        .slice(
                                          0,
                                          showAll
                                            ? hotel.animintes_list.length
                                            : initialCount
                                        )
                                        .map((facility, i) => (
                                          <div key={i} className="popList">
                                            <img
                                              src={`${ameniticsImage}${facility.animatie_code}`}
                                              alt={facility.animatie_name}
                                            />
                                            <p>{facility.animatie_name}</p>
                                          </div>
                                        ))}

                                      {!showAll &&
                                        hotel.animintes_list.length >
                                          initialCount && (
                                          <span
                                            onClick={() => setShowAll(true)}
                                            style={{
                                              cursor: "pointer",
                                              color: "#666666",
                                            }}
                                          >
                                            +{" "}
                                            {hotel.animintes_list.length -
                                              initialCount}{" "}
                                            more
                                          </span>
                                        )}
                                      {showAll && (
                                        <span
                                          onClick={() => setShowAll(false)}
                                          style={{
                                            cursor: "pointer",
                                            color: "#666666",
                                          }}
                                        >
                                          Show less
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="loctionBtmData">
                        <div className="leftData">
                          <li>• Pay on EMI available</li>
                          {parseInt(hotel.free_cancellation) > 0 && (
                            <li>• Free cancellation</li>
                          )}
                          {parseInt(hotel.express_status) > 0 && (
                            <li>• Express check-in</li>
                          )}
                        </div>
                        <div className="rightSIdeData">
                          <div className="RIghtData">
                            <p>Starting at</p>
                            <div className="underataRight">
                              {parseInt(hotel.discount) > 0 ? (
                                <>
                                  <p>{hotel.discount}% off</p>
                                  <h2>
                                    {RupessIcon}
                                    {hotel.lowest_price}
                                  </h2>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="bokkNowwBtn">
                            <h1>
                              {parseInt(hotel.discount) > 0 ? (
                                <>
                                  {RupessIcon}
                                  {hotel.discount_price}
                                </>
                              ) : (
                                <>
                                  {RupessIcon}
                                  {hotel.lowest_price}
                                </>
                              )}
                            </h1>
                            <button type="button">Book Now</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*-------------HotelQr---------------------*/}
                    {index === 0 && (
                      <div className="paddinRightAfter768 mb-4 QrHotlDiv">
                        <div className="appQr">
                          <div className="qrCard h-100">
                            <img
                              src={hotelQr2}
                              alt="QR Code"
                              className="qrImage"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            {visibleItems < HotelCategroyList.length && (
              <div
                ref={loaderRef}
                style={{ textAlign: "center", padding: "20px" }}
              >
                Loading...
              </div>
            )}
            {/* <div className="chAtUsimg">
              <img src={whatIconWhite} alt="chatUSIson"></img>
            </div> */}
          </div>
        </div>
        <SharePopup
          isOpen={isSharePopupOpen}
          onRequestClose={() => setSharePopupOpen(false)}
          Content={isSharePopupContent}
        />
      </div>
      <FilterModal
        HandleCloseFilterModal={HandleCloseFilterModal}
        FilterModalShow={FilterModalShow}
        priceInRangemin={priceInRangemin}
        priceInRangemax={priceInRangemax}
        Amenities={Amenities}
        matchesLocality={matchesLocality}
        handleFilters={handleFilters}
      />
    </div>
  );
}
