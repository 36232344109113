import React, { useState, useEffect, useRef } from "react";
import "./Css/CreateBooking.css";
import Header from "./Header";
import RightArrow from "../Assets/rightAngle.png";
import CopyIcon from "../Assets/copyIcon.png";
import Download from "../Assets/DownloadICon.png";
import verified from "../Assets/verified.png";
import Share from "../Assets/ShareIcon.png";
import Location from "../Assets/locationIcon.png";
import CancelRound from "../Assets/cancelRound.png";
import BookingLoader from "./BookingLoader.js";
import Shield from "../Assets/sheild.png";
import CallImg from "../Assets/CallImg.png";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import BackBar from "./Reapeting Componets/BackBar";
import { useLocation } from "react-router-dom";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { useRazorpay } from "react-razorpay";
import { useNavigate } from "react-router-dom";
import "./Css/SharePopup.css";
import "./Css/ReschuduleCalander.css";
import SharePopup from "./SharePopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  server_post_data,
  booking_details_all,
  update_gst_url,
  update_guest_name,
  update_payment_pre,
  update_booking_payment,
  update_reschedule_date,
  update_rating_reactNative,
  cancel_booking,
  aadhar_number_search,
  aadhar_otp_validate,
} from "../ServiceConnection/serviceconnection";
import {
  handleError,
  handleLinkClick,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
export default function UpComingBooking() {
  let customer_id = "0";
  let customer_name = "";
  let customer_mobile_no = "";
  let customer_email = "";
  const location = useLocation();
  const { Razorpay } = useRazorpay();
  const navigate = useNavigate();
  const [useSwagCoin, setUseSwagCoin] = useState(false);
  const [varifOtp, setvarifOtp] = useState(false);
  const [gstModal, setgstModal] = useState(false);
  const [guestModal, setGuestModal] = useState(false);
  const [hotelHelpModal, setHotelHelpModal] = useState(false);
  const [swagHelpModal, setSwagHelpModal] = useState(false);
  const [bookingdetails, setbookingdetails] = useState([]);
  const [hoteldetails, sethoteldetails] = useState([]);
  const [websitedetails, setwebsitedetails] = useState([]);
  const [data, setData] = useState(location.state?.data);
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [isSharePopupContent, setisSharePopupContent] = useState("");
  const [INvoiceLink, setisINvoiceLink] = useState("");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isCheckInMode, setIsCheckInMode] = useState(true);
  const dateContrRef = useRef(null);
  const [HotelMobileNumber, sethotel_manager_no] = useState("");
  const [RefundStatus, setRefundStatus] = useState("");
  const [RefundAmount, setRefundAmount] = useState("");
  const [RefundDate, setRefundDate] = useState("");
  const [RupessIcon, setRupessIcon] = useState("");
  const [MobileNo, setMobileNo] = useState("0");

  const [isCancelingpopup, setIsCancelingpopup] = useState(false);
  const [isCancelBooking, setisCancelBooking] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  const [ImageLink, setImageLink] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedTripType, setSelectedTripType] = useState("Business");
  const [selectedCompanion, setSelectedCompanion] = useState("Family");
  const [selectedHotelDescription, setSelectedHotelDescription] = useState([]);
  const [ExpressData, setExpressData] = useState([]);
  const [additionalSuggestions, setAdditionalSuggestions] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  ///otp varification
  const [referenceid, setreferenceid] = useState("0");
  const [cec_id, setcec_id] = useState("0");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isValidOtp, setIsValidOtp] = useState(true);

  const textareaRef = useRef(null);
  // const data = ;
  customer_id = retrieveData("customer_id");
  customer_name = retrieveData("customer_full_name");
  customer_mobile_no = retrieveData("customer_user_moblie_no");
  customer_email = retrieveData("customer_user_email");
  const handleSwagCoin = () => {
    setUseSwagCoin(!useSwagCoin);
  };

  const handleRadioChange = (event) => {
    const labelText = event.target
      .closest(".custom-radio")
      .querySelector(".billLabl").textContent;
    setSelectedReason(labelText);
  };

  useEffect(() => {
    if (selectedReason === "Other" && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [selectedReason]);

  const copyCode = () => {
    const code = bookingdetails.booking_id;
    navigator.clipboard.writeText(code).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("HotelID", data.HotelID);
    fd.append("hotel_id", data.hotel_id);
    fd.append("book_id", data.book_id);
    fd.append("booking_id", data.booking_id);

    await server_post_data(booking_details_all, fd)
      .then((Response) => {
        let data1 = Response.split("~@~");
        if (parseInt(data1[0]) === 1) {
          handleError(Response.data);
        } else {
          const final_data = JSON.parse(data1[1]);
          if (final_data.booking_details.length > 0) {
            setbookingdetails(final_data.booking_details[0]);
          }
          setImageLink(final_data.hotel_image_link);
          sethoteldetails(final_data.hotel_details[0]);
          setwebsitedetails(final_data.website_content);
          setisSharePopupContent(final_data.share_link);
          setisINvoiceLink(final_data.invoice_link);
          setExpressData(final_data.express_checkin_data);
          setSEOloop(final_data.seo_list);
          if (final_data.hotel_details[0].hotel_manager_no !== "") {
            sethotel_manager_no(final_data.hotel_details[0].hotel_manager_no);
          } else {
            sethotel_manager_no(final_data.website_content.customer_care);
          }
          setMobileNo(final_data.website_content.customer_care);

          setRupessIcon(final_data.rupees_icon);
          if (final_data.booking_details.length > 0) {
            setRefundStatus(final_data.booking_details[0].refund_status);
            setRefundAmount(final_data.booking_details[0].refund_amount);
            setRefundDate(final_data.booking_details[0].refund_date);
          }
          setCheckInDate(
            new Date(final_data.booking_details[0].check_in_date_original)
          );
          setCheckOutDate(
            new Date(final_data.booking_details[0].check_out_date_original)
          );
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    master_data_get();
  }, []);
  const handleSaveChangesdynamic = async (
    booking_id,
    your_address,
    legal_entity_name,
    gst_number
  ) => {
    setIsLoading(true);
    const form_data = new FormData();
    form_data.append("booking_id", booking_id);
    form_data.append("your_address", your_address);
    form_data.append("legal_entity_name", legal_entity_name);
    form_data.append("gst_number", gst_number);
    form_data.append("gst_email", "");
    form_data.append("gst_mobile_no", "");
    await server_post_data(update_gst_url, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
          setgstModal(false);
          master_data_get();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  console.log(ExpressData);
  const handleSaveChangesdynamicGuestName = async (
    booking_id,
    hotel_id,
    HotelID,
    guest_name
  ) => {
    setIsLoading(true);
    const form_data = new FormData();
    form_data.append("booking_id", booking_id);
    form_data.append("hotel_id", hotel_id);
    form_data.append("HotelID", HotelID);
    form_data.append("guest_name", guest_name);
    await server_post_data(update_guest_name, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
          master_data_get();
          setGuestModal(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const online_submit_booking = async (amount) => {
    const fd = new FormData();
    fd.append("amount", amount);
    await server_post_data(update_payment_pre, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          console.log(data[1]);
        } else {
          handlePayment(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePayment = async (data_server) => {
    try {
      const options = {
        description: "Swagstay Rest Booking Payment",
        image: "https://www.swagstay.com/logo.png",
        currency: "INR",
        key: data_server[3], // Replace with your actual Razorpay key
        amount: data_server[2],
        name: "Swagstay",
        order_id: data_server[1],
        prefill: {
          email: customer_email,
          contact: customer_mobile_no,
          name: customer_name,
        },
        theme: { color: "#F86855" },
        handler: function (response) {
          // Successful payment handler
          online_submit_booking_final(
            response.razorpay_payment_id,
            data_server[2]
          );
        },
        modal: {
          ondismiss: function () {
            // handleError("Payment process was cancelled. Please try again.");
          },
        },
      };
      // Create Razorpay instance
      const razorpayInstance = new Razorpay(options);
      // Open the Razorpay payment interface
      razorpayInstance.open();
      // Optional: Handle errors when Razorpay fails to initialize
      razorpayInstance.on("payment.failed", function (response) {
        handleError("Payment failed. Please try again.");
      });
    } catch (error) {
      handleError("Payment failed. Please try again.");
    } finally {
      // setIsLoading(false);
    }
  };

  const online_submit_booking_final = async (trans_id, amount) => {
    setIsLoading(true);
    const fd = new FormData();
    fd.append("booking_id", data.booking_id);
    fd.append("payment_mode", "Razorpay");
    fd.append("razorpay_payment_id", trans_id);
    fd.append("hotel_id", data.hotel_id);
    fd.append("amount", amount);
    fd.append("HotelID", data.HotelID);

    await server_post_data(update_booking_payment, fd)
      .then((Response) => {
        setIsLoading(false);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          console.log(data[1]);
        } else {
          master_data_get();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  // Handle date selection clicks
  const handleCheckInClick = () => {
    setIsCheckInMode(true);
    setIsCalendarOpen(true);
  };

  const handleCheckOutClick = () => {
    setIsCheckInMode(false);
    setIsCalendarOpen(true);
  };

  // Handle date changes
  const handleCheckInDateChange = (date) => {
    setCheckInDate(date);
    setIsCheckInMode(false);
    if (!checkOutDate || date >= checkOutDate) {
      // If checkout date is not set or is before new check-in date,
      // set checkout to the next day
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);
      setCheckOutDate(nextDay);
    }
  };

  const handleCheckOutDateChange = (date) => {
    setCheckOutDate(date);
    setIsCalendarOpen(false);
  };

  // Close calendar when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dateContrRef.current &&
        !dateContrRef.current.contains(event.target)
      ) {
        setIsCalendarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateContrRef]);

  // Handle reschedule submission
  const handleRescheduleSubmit = async () => {
    if (!checkInDate || !checkOutDate) {
      handleError("Please select both check-in and check-out dates");
      return;
    }

    handleReschedule();
  };

  const handleReschedule = async () => {
    setIsLoading(true);
    const form_data = new FormData();
    form_data.append("booking_id", bookingdetails.booking_id);
    form_data.append("hotel_id", data.hotel_id);
    form_data.append("HotelID", data.HotelID);
    form_data.append("book_id", bookingdetails.book_id);
    form_data.append("no_of_room", bookingdetails.no_of_room);
    form_data.append("check_in_date", checkInDate.toISOString().split("T")[0]);
    form_data.append(
      "check_out_date",
      checkOutDate.toISOString().split("T")[0]
    );
    await server_post_data(update_reschedule_date, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
          setRescheduleModal(false);
          master_data_get();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleOpenFeedbackModal = () => setShowFeedbackModal(true);
  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
    setSelectedFeedback(null);
    setSelectedTripType(null);
    setSelectedCompanion(null);
    setSelectedHotelDescription([]);
  };

  const handleSingleSelect = (setter, value) => {
    setter((prev) => (prev === value ? null : value));
  };

  const handleMultiSelect = (value) => {
    setSelectedHotelDescription((prev) =>
      prev.includes(value)
        ? prev.filter((desc) => desc !== value)
        : [...prev, value]
    );
  };

  const submitFeedback = () => {
    const feedbackData = {
      emojiFeedback: selectedFeedback,
      tripType: selectedTripType,
      travelWith: selectedCompanion,
      hotelDescription: selectedHotelDescription,
      additionalSuggestions: additionalSuggestions,
    };

    handleSaveChangesdynamicaa(
      bookingdetails.booking_id,
      bookingdetails.cbh_id,
      feedbackData.emojiFeedback,
      feedbackData.tripType,
      feedbackData.travelWith,
      feedbackData.hotelDescription.join(","),
      feedbackData.additionalSuggestions
    );
  };

  const handleSaveChangesdynamicaa = async (
    booking_id,
    cbh_id,
    feedback,
    trip_type,
    travel_with,
    hotel_type,
    review
  ) => {
    const form_data = new FormData();
    form_data.append("booking_id", booking_id);
    form_data.append("cbh_id", cbh_id);
    form_data.append("trip_type", trip_type);
    form_data.append("travel_with", travel_with);
    form_data.append("hotel_type", hotel_type);
    form_data.append("feedback", feedback);
    form_data.append("review", review);
    form_data.append("location_str", 0);
    form_data.append("cleaning_str", 0);
    form_data.append("services_str", 0);
    form_data.append("price_str", 0);
    await server_post_data(update_rating_reactNative, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleCloseFeedbackModal();
          // navigation.navigate('Main');
        }
      })
      .catch((error) => {
        handleError("network");
        console.log(error);
      });
  };

  const handleCancelPress = () => {
    setIsCancelingpopup(true);
    // Scroll to the popup after setting isCanceling to true
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const handleCancelBooking = () => {
    // Add your cancellation logic here
    handleSaveChangesdynamiccancel();
  };
  ///otp varication Modal
  const handleShowotpModal = (AddharNo, cec_iddata) => {
    SearchAadharNo(AddharNo, cec_iddata);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    if (error) {
      setError("");
    }
  };

  const handleSubmitOtp = () => {
    if (!otp) {
      setError("Please fill in the OTP");
      setIsValidOtp(false);
    } else {
      ValidateAadharOTP(otp);
    }
  };

  const handleSaveChangesdynamiccancel = async () => {
    setIsLoading(true);
    const form_data = new FormData();
    form_data.append("booking_id", data.booking_id);
    form_data.append("hotel_id", data.hotel_id);
    form_data.append("HotelID", data.HotelID);
    form_data.append("remarks", selectedReason);
    console.log("selectedReason", selectedReason);
    await server_post_data(cancel_booking, form_data)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
          master_data_get();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const match_and_return_seo_link = (HotelID, hotel_id) => {
    let data_seo_link_final = "hotel_detail/" + HotelID + "/" + hotel_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  const handlebookclick = (hotel_id, HotelID) => {
    let link_url = match_and_return_seo_link(HotelID, hotel_id);
    handleLinkClick("/" + link_url);
  };

  const SearchAadharNo = async (aadhar_no, cec_iddata) => {
    const fd = new FormData();
    fd.append("flag_from", 2);
    fd.append("aadhar_number", aadhar_no);
    fd.append("cec_id", cec_iddata);
    setIsLoading(true);
    await server_post_data(aadhar_number_search, fd)
      .then((Response) => {
        setIsLoading(false);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          setreferenceid(data[1]);
          setcec_id(cec_iddata);
          setvarifOtp(!varifOtp);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const ValidateAadharOTP = async (otp) => {
    const fd = new FormData();
    fd.append("flag_from", 2);
    fd.append("aadhar_otp_validate", otp);
    fd.append("referenceid", referenceid);
    fd.append("cec_id", cec_id);
    setIsLoading(true);
    await server_post_data(aadhar_otp_validate, fd)
      .then((Response) => {
        setIsLoading(false);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          handleSuccess(data[1]);
          setIsValidOtp(true);
          setvarifOtp(false);
          master_data_get();
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ backgroundColor: "var(--light-bg)" }}>
      <div className="navBarCOntainer">
        <Header />
        {showLoaderAdmin && <BookingLoader />}
        {isLoading && <BookingLoader />}
        {bookingdetails &&
          (() => {
            if (bookingdetails.booking_status === "Disabled") {
              return <BackBar label={"Cancelled Booking"} />;
            } else if (isCancelBooking) {
              return <BackBar label={"Cancelled Booking"} />;
            } else if (bookingdetails.checkin_status === "Arrival") {
              return <BackBar label={"Upcoming Booking"} />;
            } else if (bookingdetails.checkin_status === "CheckedOut") {
              return <BackBar label={"Completed Booking"} />;
            } else if (bookingdetails.checkin_status === "Not Show") {
              return <BackBar label={"Not Show Booking"} />;
            } else if (bookingdetails.checkin_status === "Checked In") {
              return <BackBar label={"In house Booking"} />;
            } else {
              return <BackBar label="" />;
            }
          })()}
      </div>

      <div className="container-lg">
        <div className="createBookingContainer">
          {/* Booking Details COntianer */}
          {!isCancelBooking && (
            <>
              <section className="bookDetails mt-4">
                {bookingdetails &&
                  !isCancelingpopup &&
                  (() => {
                    if (bookingdetails.checkin_status === "Arrival") {
                      return (
                        <div className="StatusMsg">
                          <h5>Your booking has been confirmed</h5>
                        </div>
                      );
                    } else if (bookingdetails.checkin_status === "Checked In") {
                      return (
                        <div className="StatusMsg">
                          <h5>Your booking has been confirmed</h5>
                        </div>
                      );
                    }
                  })()}

                {isCancelingpopup && (
                  <>
                    <div className="cancelMsgs">
                      <h5>Tussi ja rahe ho, tussi na jao :(</h5>
                      <p>
                        Arre bhai, booking cancel karni hai? Soch lo yaar! 🤔
                        plan banaya tha mast stay ke liye, ab kyun cancel karna
                        hai?
                      </p>
                    </div>
                    <div className="continueBtn_container cnclBtns">
                      <button className="primaryBtn">No fomo,let’s go</button>
                      <button
                        className="secondaryBtn"
                        onClick={() => setisCancelBooking(true)}
                      >
                        Kar de cancel !!
                      </button>
                    </div>
                  </>
                )}

                <div className="bookDetailsContainer bookingReciptCon mb-4">
                  <div className="booldetailTop">
                    <div className="left_detail">
                      <div className="bookingId">
                        <p>
                          Booking id: <span>{bookingdetails.booking_id}</span>
                        </p>
                        <img onClick={copyCode} src={CopyIcon} />
                      </div>
                    </div>
                    <div className="right_detail">
                      <div className="bookingId">
                        <p>
                          Status:{" "}
                          {bookingdetails &&
                            (() => {
                              if (
                                bookingdetails.booking_status === "Disabled"
                              ) {
                                return <span>Disabled</span>;
                              } else {
                                return <span>Confirmed</span>;
                              }
                            })()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bookDetailsBottom">
                    <div className="leftSection">
                      <div className="checkin-out-container">
                        <div className="check-section">
                          <p className="label">Check in</p>
                          <p className="date">
                            {console.log(bookingdetails.check_in_date)}
                            {bookingdetails.check_in_date
                              ? bookingdetails.check_in_date.split(",")[0]
                              : ""}
                          </p>
                          <p className="time">
                            {bookingdetails.check_in_date
                              ? bookingdetails.check_in_date
                                  .split(",")[1]
                                  .trim()
                              : ""}
                          </p>
                        </div>

                        <div className="duration">
                          <span className="nights">
                            {bookingdetails && bookingdetails.total_nights} N
                          </span>
                        </div>

                        <div className="check-section">
                          <p className="label">Check out</p>
                          <p className="date">
                            {bookingdetails.check_out_date
                              ? bookingdetails.check_out_date.split(",")[0]
                              : ""}
                          </p>
                          <p className="time">
                            {bookingdetails.check_out_date
                              ? bookingdetails.check_out_date
                                  .split(",")[1]
                                  .trim()
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="saperatorVertical"></div>
                      <div className="hotelText">
                        <p className="cardHeading mb-2">
                          {hoteldetails.hotel_name}
                        </p>
                        <p className="cardText mb-1">{hoteldetails.address}</p>
                        <div
                          className="discount_price"
                          onClick={() => {
                            setSharePopupOpen(true);
                          }}
                        >
                          <button className="reciptBtns">
                            <img src={Share} alt="Swagstay" />
                          </button>
                          <button
                            className="reciptBtns"
                            onClick={() =>
                              window.open(
                                `https://www.google.com/maps/dir/?api=1&destination=${hoteldetails.latitude},${hoteldetails.longitude}`,
                                "_blank"
                              )
                            }
                          >
                            <img src={Location} alt="Swagstay" />
                          </button>
                          {bookingdetails &&
                            (() => {
                              if (
                                bookingdetails.checkin_status === "CheckedOut"
                              ) {
                                return (
                                  <button
                                    className="reciptBtns"
                                    onClick={() =>
                                      window.open(INvoiceLink, "_blank")
                                    }
                                  >
                                    <img src={Download} alt="Swagstay" />
                                  </button>
                                );
                              }
                            })()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="reciptBookingTxt">
                    {bookingdetails &&
                      (() => {
                        if (bookingdetails.booking_status === "Disabled") {
                          return (
                            <p>
                              {bookingdetails.per_name} your booking has been{" "}
                              <span style={{ color: "var(--light-red)" }}>
                                Cancelled.
                              </span>
                            </p>
                          );
                        } else if (
                          bookingdetails.checkin_status === "Arrival"
                        ) {
                          return (
                            <p>
                              {bookingdetails.per_name} your booking has been{" "}
                              <span>confirmed.</span>
                            </p>
                          );
                        } else if (
                          bookingdetails.checkin_status === "CheckedOut"
                        ) {
                          return (
                            <p>
                              Thank You {bookingdetails.per_name}! Your stay has
                              been <span>completed.</span>
                            </p>
                          );
                        } else if (
                          bookingdetails.checkin_status === "Not Show"
                        ) {
                          return (
                            <p>
                              {bookingdetails.per_name}, you haven't arrived at
                              the hotel.{" "}
                            </p>
                          );
                        } else if (
                          bookingdetails.checkin_status === "Checked In"
                        ) {
                          return (
                            <p>
                              {bookingdetails.per_name} your booking has been{" "}
                              <span>confirmed.</span>
                            </p>
                          );
                        }
                      })()}
                  </div>
                </div>
              </section>

              {/* Left Section Items  */}
              <div className="row m-0">
                <section className="col-md-6 paddinLeftAfter768">
                  {RefundStatus !== "No" && parseInt(RefundAmount) > 0 && (
                    <div className="earlyLateColumn mb-4">
                      <div className="earlyLatecontainer mb-4">
                        <h5
                          className="headingh5 mb-3"
                          style={{ color: "var(--text-black)" }}
                        >
                          Refund status
                        </h5>
                        <div className="billDetails">
                          <div className="billDescp">
                            <p className="billLabl">Refund initiate date</p>
                            <p className="cardText">{RefundDate}</p>
                          </div>
                          <div className="separator"></div>
                          <div className="billDescp">
                            <p className="billLabl">Current status</p>
                            <p
                              className="cardText"
                              style={{
                                color: "var(--dark-green)",
                                fontWeight: 600,
                              }}
                            >
                              {RefundStatus === "Pending"
                                ? "Admin level"
                                : RefundStatus === "On Going"
                                ? "Refund initiated"
                                : "Refund Success"}
                            </p>
                          </div>
                          <div className="separator mb-0"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Bill Details Section */}
                  <div className="earlyLateColumn mb-4">
                    <div className="earlyLatecontainer mb-4">
                      <h5
                        className="headingh5 mb-3"
                        style={{ color: "var(--text-black)" }}
                      >
                        Booking details
                      </h5>
                      <div className="billDetails">
                        <div className="billDescp">
                          <p className="billLabl">Booking for</p>
                          <p className="cardText">{bookingdetails.per_name}</p>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Total Night</p>
                          <p className="cardText">
                            {bookingdetails.total_nights}N
                          </p>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Guest count</p>
                          <p className="cardText">
                            {bookingdetails.adult} Adults |{" "}
                            {bookingdetails.no_of_room} Room
                          </p>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Room type</p>
                          <p className="cardText">{bookingdetails.room_type}</p>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Base Hotel charges</p>
                          <p className="cardText">
                            {RupessIcon}
                            {bookingdetails.room_rate}
                          </p>
                        </div>
                        {bookingdetails &&
                          parseInt(bookingdetails.breakfast_price) > 0 && (
                            <>
                              {console.log(bookingdetails.breakfast_price)}
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl"> Breakfast meal</p>
                                <p className="cardText">
                                  {RupessIcon}
                                  {bookingdetails.breakfast_price}
                                </p>
                              </div>
                            </>
                          )}
                        {bookingdetails &&
                          parseInt(bookingdetails.lunch_price) > 0 && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl"> Lunch meal</p>
                                <p className="cardText">
                                  {RupessIcon}
                                  {bookingdetails.lunch_price}
                                </p>
                              </div>
                            </>
                          )}
                        {bookingdetails &&
                          parseInt(bookingdetails.dinner_price) > 0 && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl"> Dinner meal</p>
                                <p className="cardText">
                                  {RupessIcon}
                                  {bookingdetails.dinner_price}
                                </p>
                              </div>
                            </>
                          )}
                        {bookingdetails &&
                          parseInt(bookingdetails.checkinprice) > 0 && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl">
                                  {" "}
                                  Early check-in charges
                                </p>
                                <p className="cardText">
                                  {RupessIcon}
                                  {bookingdetails.checkinprice}
                                </p>
                              </div>
                            </>
                          )}

                        {bookingdetails &&
                          parseInt(bookingdetails.checkoutprice) > 0 && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl">
                                  Late check-out charges
                                </p>
                                <p className="cardText">
                                  {RupessIcon}
                                  {bookingdetails.checkoutprice}
                                </p>
                              </div>
                            </>
                          )}
                        {bookingdetails &&
                          parseInt(bookingdetails.discount_amount) > 0 && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl"> Coupon discount</p>
                                <p className="cardText">
                                  {RupessIcon}
                                  {bookingdetails.discount_amount}
                                </p>
                              </div>
                            </>
                          )}
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Booking payement</p>
                          <p className="cardText">
                            {RupessIcon}
                            {bookingdetails.total_payment}
                          </p>
                        </div>
                        {bookingdetails &&
                          parseInt(bookingdetails.wallet_amount) > 0 && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl"> Coin redeemed</p>
                                <p className="cardText">
                                  {RupessIcon}
                                  {bookingdetails.wallet_amount}
                                </p>
                              </div>
                            </>
                          )}
                        {bookingdetails &&
                          parseInt(bookingdetails.real_wallet_amount) > 0 && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl"> Real Wallet</p>
                                <p className="cardText">
                                  {RupessIcon}
                                  {bookingdetails.real_wallet_amount}
                                </p>
                              </div>
                            </>
                          )}
                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Advance payment</p>
                          <p className="cardText">
                            {RupessIcon}
                            {bookingdetails.advacnce_amount || 0}
                          </p>
                        </div>

                        <div className="separator"></div>
                        <div className="billDescp">
                          <p className="billLabl">Final payment</p>
                          <p className="cardText">
                            {RupessIcon}
                            {bookingdetails.total_final_amount}
                          </p>
                        </div>
                        {bookingdetails &&
                          bookingdetails.pickup_location !== "" && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl">Pickup Location</p>
                                <p className="cardText">
                                  {bookingdetails.pickup_location}
                                </p>
                              </div>
                            </>
                          )}
                        {bookingdetails &&
                          bookingdetails.drop_location !== "" && (
                            <>
                              <div className="separator"></div>
                              <div className="billDescp">
                                <p className="billLabl">Drop Location</p>
                                <p className="cardText">
                                  {bookingdetails.drop_location}
                                </p>
                              </div>
                            </>
                          )}
                        <div className="separator"></div>
                      </div>
                    </div>
                  </div>
                  {bookingdetails?.booking_status !== "Disabled" && (
                    <div className="earlyLateColumn position-relative mb-4">
                      <div className="earlyLatecontainer mb-4">
                        <h5 className="headingh5 mb-3">GST details</h5>
                        <div className="selectTimeWrapper gstBtn">
                          <div className="mealOption couponBtn m-0">
                            <input
                              type="checkbox"
                              id="swagCoin"
                              name="swagCoin"
                              checked={useSwagCoin}
                              onChange={handleSwagCoin}
                              className="customCheckbox"
                            />
                            <label
                              htmlFor="swagCoin"
                              style={{
                                fontWeight: 600,
                                color: "var(--text-black)",
                                whiteSpace: "nowrap",
                                margin: 0,
                              }}
                            >
                              <span
                                className="customCheckboxIcon"
                                style={{ minWidth: "20px" }}
                              ></span>
                              Save GST on this booking
                            </label>
                            <span
                              className="swagCoins w-100 d-flex justify-content-end"
                              style={{ cursor: "pointer" }}
                              onClick={() => setgstModal(true)}
                            >
                              <img
                                src={RightArrow}
                                className="rightArrow"
                                alt="Icon"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </section>

                <section className="col-md-6 paddinRightAfter768">
                  {/*Adhar Section*/}
                  {bookingdetails?.booking_status !== "Disabled" &&
                    bookingdetails.checkin_status !== "CheckedOut" && (
                      <div className="earlyLateColumn pb-0 mb-4">
                        <h5 className="guesDtlHedd">Guest details</h5>
                        {ExpressData.map((item, index) => (
                          <div className="earlyLatecontainer mb-4">
                            {/* <h5
                              className="headingh5 mb-3"
                              style={{ color: "var(--text-black)" }}
                            >
                              Name
                            </h5>{" "} */}

                            <div className="d-flex justify-content-between align-items-center">
                              <p
                                className="billLabl "
                                style={{ marginBottom: "5px" }}
                              >
                                Name
                              </p>
                              <p className="cardText">{item.guest_name}</p>
                            </div>

                            <div className="billDetails" key={index}>
                              <div
                                className="billDescp popUpBtns"
                                onClick={() => setGuestModal(true)}
                              >
                                <p className="billLabl">Adhar Details:</p>
                              </div>

                              <div className="AdharVarinput">
                                <div className="inputConyt">
                                  <input
                                    type="text"
                                    maxLength={12}
                                    className="form-control adharInputClasss"
                                    placeholder="000 000 000"
                                    value={item.guest_aadhar_no}
                                    readOnly
                                  ></input>
                                </div>
                                <div className="inputConyt2">
                                  {parseInt(item.verified_status) === 1 ? (
                                    <img src={verified} alt="verified" />
                                  ) : (
                                    <p
                                      onClick={() =>
                                        handleShowotpModal(
                                          item.guest_aadhar_no,
                                          item.cec_id
                                        )
                                      }
                                    >
                                      Verify
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="separator"></div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  {/* Manage Booking Section */}
                  {bookingdetails?.booking_status !== "Disabled" &&
                    bookingdetails.checkin_status !== "CheckedOut" && (
                      <div className="earlyLateColumn pb-0 mb-4">
                        <div className="earlyLatecontainer mb-4">
                          <h5
                            className="headingh5 mb-3"
                            style={{ color: "var(--text-black)" }}
                          >
                            Manage your booking
                          </h5>
                          <div className="billDetails">
                            <div
                              className="billDescp popUpBtns"
                              onClick={() => setGuestModal(true)}
                            >
                              <p className="billLabl">Modify guest name</p>
                              <p className="cardText">
                                <img
                                  src={RightArrow}
                                  className="rightArrow"
                                  alt="Icon"
                                />
                              </p>
                            </div>
                            <div className="separator"></div>
                            <div
                              className="billDescp popUpBtns"
                              onClick={() => setRescheduleModal(true)}
                            >
                              <p className="billLabl">
                                Rescheduled your booking
                              </p>
                              <p className="cardText">
                                <img
                                  src={RightArrow}
                                  className="rightArrow"
                                  alt="Icon"
                                />
                              </p>
                            </div>
                            <Modal
                              show={rescheduleModal}
                              onHide={() => setRescheduleModal(false)}
                              className="GSTModal"
                              size="md"
                              centered
                            >
                              <Modal.Header closeButton>
                                <h4>Reschedule Booking</h4>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="formCOntainer">
                                  <div
                                    ref={dateContrRef}
                                    className="dateCOntrrr"
                                  >
                                    <div className="date-section">
                                      <div
                                        className="check-in"
                                        onClick={handleCheckInClick}
                                      >
                                        <p className="Detltitile">Check-in</p>
                                        <p className="dateTxt">
                                          {checkInDate
                                            ? formatDate(checkInDate)
                                            : "Select date"}
                                        </p>
                                      </div>
                                      <div
                                        className="check-out"
                                        onClick={handleCheckOutClick}
                                      >
                                        <p className="Detltitile">Check-out</p>
                                        <p className="dateTxt">
                                          {checkOutDate
                                            ? formatDate(checkOutDate)
                                            : "Select date"}
                                        </p>
                                      </div>
                                      {isCalendarOpen && (
                                        <div className="checkInCalanderHotelDEtail horizontal-calendar-container">
                                          <DatePicker
                                            selected={
                                              isCheckInMode
                                                ? checkInDate
                                                : checkOutDate
                                            }
                                            onChange={
                                              isCheckInMode
                                                ? handleCheckInDateChange
                                                : handleCheckOutDateChange
                                            }
                                            className="form-control border-0 p-0 text-muted"
                                            placeholderText={
                                              isCheckInMode
                                                ? "Select check-in date"
                                                : "Select check-out date"
                                            }
                                            dateFormat="dd MMM yyyy"
                                            inline
                                            selectsStart={isCheckInMode}
                                            selectsEnd={!isCheckInMode}
                                            startDate={checkInDate}
                                            endDate={checkOutDate}
                                            minDate={
                                              isCheckInMode
                                                ? new Date()
                                                : checkInDate
                                            }
                                            monthsShown={2}
                                            calendarClassName="horizontal-calendar"
                                            renderCustomHeader={({
                                              monthDate,
                                              customHeaderCount,
                                              decreaseMonth,
                                              increaseMonth,
                                            }) => (
                                              <div>
                                                <button
                                                  aria-label="Previous Month"
                                                  className="react-datepicker__navigation react-datepicker__navigation--previous"
                                                  style={
                                                    customHeaderCount === 1
                                                      ? { visibility: "hidden" }
                                                      : null
                                                  }
                                                  onClick={decreaseMonth}
                                                >
                                                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                                                    {"<"}
                                                  </span>
                                                </button>
                                                <span className="react-datepicker__current-month">
                                                  {monthDate.toLocaleString(
                                                    "en-US",
                                                    {
                                                      month: "long",
                                                      year: "numeric",
                                                    }
                                                  )}
                                                </span>
                                                <button
                                                  aria-label="Next Month"
                                                  className="react-datepicker__navigation react-datepicker__navigation--next"
                                                  style={
                                                    customHeaderCount === 0
                                                      ? { visibility: "hidden" }
                                                      : null
                                                  }
                                                  onClick={increaseMonth}
                                                >
                                                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                                                    {">"}
                                                  </span>
                                                </button>
                                              </div>
                                            )}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="DarkButtonClass"
                                  onClick={handleRescheduleSubmit}
                                >
                                  Reschedule Booking
                                </button>
                              </Modal.Footer>
                            </Modal>
                            <div className="separator"></div>
                            <Link
                              onClick={() => handleCancelPress()}
                              state={{
                                data: data,
                              }}
                            >
                              <div className="billDescp popUpBtns">
                                <p className="billLabl">Cancel booking</p>
                                <p className="cardText">
                                  <img
                                    src={RightArrow}
                                    className="rightArrow"
                                    alt="Icon"
                                  />
                                </p>
                              </div>
                            </Link>
                            <div className="separator mb-0"></div>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* Need Help Section */}
                  {bookingdetails &&
                    bookingdetails.checkin_status === "CheckedOut" && (
                      <div className="earlyLateColumn pb-0 mb-4">
                        <div className="earlyLatecontainer mb-4">
                          <h5
                            className="headingh5 mb-3"
                            style={{ color: "var(--text-black)" }}
                          >
                            Write review ⭐
                          </h5>
                          <div className="billDetails">
                            <div
                              className="billDescp popUpBtns"
                              onClick={handleOpenFeedbackModal}
                            >
                              <p className="billLabl">
                                {" "}
                                Give feedback to hotel
                              </p>
                              <p className="cardText">
                                <img
                                  src={RightArrow}
                                  className="rightArrow"
                                  alt="Icon"
                                />
                              </p>
                            </div>
                            <div className="separator mb-0"></div>
                          </div>
                        </div>
                      </div>
                    )}

                  {bookingdetails &&
                    bookingdetails.checkin_status === "Not Show" &&
                    bookingdetails.booking_status !== "Disabled" && (
                      <div className="earlyLateColumn pb-0 mb-4">
                        <div className="earlyLatecontainer mb-4">
                          <h5
                            className="headingh5 mb-3"
                            style={{ color: "var(--text-black)" }}
                          >
                            What is no show
                          </h5>
                          <div className="billDetails">
                            <ul>
                              <li>
                                A no-show occurs when a guest does not arrive at
                                the hotel on the scheduled check-in date and
                                does not cancel the reservation in advance.
                              </li>
                              <li>
                                To avoid no-show, please ensure you cancel your
                                reservation within the specified cancellation
                                period. You can cancel your reservation through
                                our app, website, or by contacting the hotel
                                directly.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                  {RefundStatus !== "No" && parseInt(RefundAmount) > 0 && (
                    <div className="earlyLateColumn pb-0 mb-4">
                      <div className="earlyLatecontainer mb-4">
                        <h5
                          className="headingh5 mb-3"
                          style={{ color: "var(--text-black)" }}
                        >
                          What is Refund Process
                        </h5>
                        <div className="billDetails">
                          <ul>
                            <li>
                              If you do not reach the hotel on the booking date,
                              50% of the booking amount will be credited to your
                              wallet.
                            </li>
                            <li>
                              The refund will be processed once the
                              administration confirms the no-show status.
                            </li>
                            <li>
                              The credited amount will reflect in your wallet
                              within 2-3 business days.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="earlyLateColumn pb-0 mb-4">
                    <div className="earlyLatecontainer mb-4">
                      <h5
                        className="headingh5 mb-3"
                        style={{ color: "var(--text-black)" }}
                      >
                        Need Help
                      </h5>
                      <div className="billDetails">
                        {bookingdetails.checkin_status !== "Not Show" && (
                          <>
                            <div
                              className="billDescp popUpBtns"
                              onClick={() => setHotelHelpModal(true)}
                            >
                              <p className="billLabl">Call hotel helpline</p>
                              <p className="cardText">
                                <img
                                  src={RightArrow}
                                  className="rightArrow"
                                  alt="Icon"
                                />
                              </p>
                            </div>
                            <div className="separator"></div>
                          </>
                        )}
                        <div
                          className="billDescp popUpBtns"
                          onClick={() => setSwagHelpModal(true)}
                        >
                          <p className="billLabl">
                            Call SwagStay customer care
                          </p>
                          <p className="cardText">
                            <img
                              src={RightArrow}
                              className="rightArrow"
                              alt="Icon"
                            />
                          </p>
                        </div>
                        <div className="separator mb-0"></div>
                        {bookingdetails &&
                          bookingdetails.checkin_status === "CheckedOut" && (
                            <div className="continueBtn_container">
                              <button
                                className="DarkButtonClass w-100"
                                onClick={() =>
                                  handlebookclick(data.hotel_id, data.HotelID)
                                }
                              >
                                Rebook Hotel
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {RefundStatus === "Complete" &&
                    parseInt(RefundAmount) > 0 && (
                      <>
                        {/* Refund Process Section */}
                        <div className="earlyLateColumn pb-0 mb-4">
                          <div className="earlyLatecontainer mb-4">
                            <h5
                              className="headingh5 mb-3"
                              style={{ color: "var(--text-black)" }}
                            >
                              Check refund amount
                            </h5>
                            <div className="sucssMgs">
                              <p>
                                Your refund amount {RefundAmount} will be
                                successfully credited to your Swagwallet, Check
                                your wallet balance.
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {bookingdetails.balance_amount > 0 &&
                    bookingdetails.booking_status !== "Disabled" &&
                    bookingdetails.checkin_status !== "Not Show" && (
                      <div className="continueBtn_container">
                        <Link to="" className="w-100">
                          <button
                            className="primaryBtn w-1000"
                            onClick={() =>
                              online_submit_booking(
                                bookingdetails.balance_amount
                              )
                            }
                          >
                            Pay now {RupessIcon}
                            {bookingdetails.balance_amount}
                          </button>
                        </Link>
                      </div>
                    )}
                </section>
              </div>

              {/* Policies Section */}
              <div style={{ paddingBottom: "2rem" }}>
                <button
                  className="policyBtn reciptBtns"
                  onClick={() => navigate("/guest_policy")}
                >
                  <img src={Shield} alt="Swagstay" />
                  <p>Hotel rules & policy</p>
                </button>
                <button
                  className="policyBtn reciptBtns"
                  onClick={() => navigate("/terms_condition")}
                >
                  <img src={CancelRound} alt="Swagstay" />
                  <p>Cancellation policy</p>
                </button>
              </div>
            </>
          )}
          {isCancelBooking && (
            <>
              <div className="row m-0">
                <section className="col-md-6 paddinLeftAfter768">
                  {/* Bill Details Section */}
                  <div className="detailsTxt">
                    <p>
                      We’re sorry to hear that you need to cancel your booking.
                      Please select the reason for your cancellation from the
                      options below:
                    </p>
                  </div>
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Reason to cancel
                  </h5>
                  <div className="earlyLateColumn mb-4 pb-0">
                    <div className="earlyLatecontainer mb-4">
                      <div className="billDetails">
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Changes of travel plans
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Medical reason
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Work or business commitments
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Personal reasons
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Found a better rate
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Weather conditions
                            </p>
                          </label>
                        </div>
                        <div className="separator"></div>
                        <div className="billDescp cancelReasonsCon">
                          <label className="custom-radio">
                            <input
                              type="radio"
                              name="travelPlan"
                              onChange={handleRadioChange}
                            />
                            <span className="radio-checkmark"></span>
                            <p
                              className="billLabl"
                              style={{ marginLeft: "0.3rem" }}
                            >
                              Other
                            </p>
                          </label>
                        </div>
                        <div className="separator mb-0"></div>
                      </div>
                    </div>
                  </div>

                  {/* Other Reason Section */}
                  <h5
                    className="headingh5 mb-3"
                    style={{ color: "var(--text-black)" }}
                  >
                    Other reason type here
                  </h5>
                  <div className="earlyLateColumn mb-4 p-1">
                    <textarea
                      className="reasonTextarea"
                      placeholder="Type here..."
                      disabled={selectedReason !== "Other"}
                      ref={textareaRef}
                    ></textarea>
                  </div>

                  {/* Cancel Button */}
                  <div className="continueBtn_container">
                    <Link to="/bookings_details" state={{ data: data }}>
                      <button
                        className="DarkButtonClass"
                        onClick={() => {
                          setisCancelBooking(false);
                          handleCancelBooking();
                        }}
                      >
                        Cancel Booking
                      </button>
                    </Link>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
        <SharePopup
          isOpen={isSharePopupOpen}
          onRequestClose={() => setSharePopupOpen(false)}
          Content={isSharePopupContent}
        />
      </div>
      {/* Modals */}
      {/**-----------------------varification Otp Modal------------------- */}
      <Modal
        show={varifOtp}
        onHide={() => setvarifOtp(false)}
        className="otpVarModal"
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <h4>Otp Verification</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="varificatioNotpDiv">
            <div className="inputContainer">
              <div>
                <div className="w-100">
                  <input
                    className={`form-control ${
                      !isValidOtp
                        ? "border-danger"
                        : isValidOtp && otp
                        ? "border-success"
                        : ""
                    }`}
                    type="text"
                    name="legal_entity_name"
                    placeholder="Enter the otp"
                    maxLength={6}
                    value={otp}
                    onChange={handleOtpChange}
                  />
                  {error && (
                    <span className="text-danger otpVaConditon">{error}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="submtotpbttn">
              <button
                type="button"
                className="DarkButtonClass"
                onClick={() => handleSubmitOtp()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* GST Modal */}
      <Modal
        show={gstModal}
        onHide={() => setgstModal(false)}
        className="GSTModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4>GST details</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="formCOntainer" id="gstForm">
            <div className="inputContainer">
              <label>Company name</label>
              <div className="w-100">
                <input
                  type="text"
                  id="legal_entity_name"
                  name="legal_entity_name"
                  placeholder="Type here"
                />
              </div>
            </div>
            <div className="inputContainer">
              <label>GSTIN number</label>
              <div className="w-100">
                <input
                  type="text"
                  id="gst_number"
                  name="gst_number"
                  placeholder="Type here"
                />
              </div>
            </div>
            <div className="inputContainer">
              <label>Address</label>
              <div className="w-100">
                <input
                  type="text"
                  id="your_address"
                  name="your_address"
                  placeholder="Type here"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="DarkButtonClass"
            onClick={() => {
              const booking_id = bookingdetails.booking_id; // Replace with actual booking_id
              const your_address =
                document.getElementById("your_address").value;
              const legal_entity_name =
                document.getElementById("legal_entity_name").value;
              const gst_number = document.getElementById("gst_number").value;

              handleSaveChangesdynamic(
                booking_id,
                your_address,
                legal_entity_name,
                gst_number
              );

              setGuestModal();
            }}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
      {/* Modify Guest Modal */}
      <Modal
        show={guestModal}
        onHide={() => setGuestModal(false)}
        className="GSTModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4>Modify guest name</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="formCOntainer">
            <div className="inputContainer">
              <label>Update guest name</label>
              <div className="w-100">
                <input
                  type="text"
                  id="guest_name"
                  name="guest_name"
                  placeholder="Type here"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="DarkButtonClass"
            onClick={() => {
              const booking_id = bookingdetails.booking_id; // Replace with actual booking_id
              const hotel_id = hoteldetails.hotel_id; // Replace with actual hotel_id
              const HotelID = hoteldetails.HotelID; // Replace with actual HotelID
              const guest_name = document.getElementById("guest_name").value;

              handleSaveChangesdynamicGuestName(
                booking_id,
                hotel_id,
                HotelID,
                guest_name
              );
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* Hotel HelpLine Modal */}
      <Modal
        show={hotelHelpModal}
        onHide={() => setHotelHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Hotel helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">{HotelMobileNumber}</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>

      {/* Swagstay HelpLine Modal */}
      <Modal
        show={swagHelpModal}
        onHide={() => setSwagHelpModal(false)}
        className="GSTModal helpModal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 style={{ color: "var(--primary-color)", margin: "0" }}>
            Swagstay helpline
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="helpCOntainer">
            <div className="helplineNo">
              <p className="helpTime">24X7 Number</p>
              <p className="helpNo">{MobileNo}</p>
            </div>
            <img src={CallImg} alt="Swagstay" />
          </div>
        </Modal.Body>
      </Modal>

      {showFeedbackModal && (
        <div
          className="feedback-modal-overlay"
          onClick={handleCloseFeedbackModal}
        >
          <div
            className="feedback-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="feedback-modal-heading">Feedback</h2>
            <div className="feedback-header">
              <img
                src={`${ImageLink}${hoteldetails.image_name}`}
                alt="Hotel"
                className="feedbackhotelimage"
              />
              <div>
                <h3 className="hotel-name">{hoteldetails.hotel_name}</h3>
                <p className="hotel-address">{hoteldetails.address}</p>
              </div>
            </div>

            <div className="feedback-section">
              <h5>Give feedback</h5>
              <div className="feedback-options">
                {[
                  { emoji: "😀", description: "Amazing", value: 5.0 },
                  { emoji: "😊", description: "Good", value: 4.0 },
                  { emoji: "😐", description: "Okay", value: 3.0 },
                  { emoji: "😕", description: "Bad", value: 2.0 },
                  { emoji: "😢", description: "Terrible", value: 1.0 },
                ].map((text, index) => (
                  <button
                    key={index}
                    className={
                      selectedFeedback === text.value ? "selected" : ""
                    }
                    onClick={() =>
                      handleSingleSelect(setSelectedFeedback, text.value)
                    }
                  >
                    {text.emoji} {text.description}
                  </button>
                ))}
              </div>
            </div>

            <textarea
              placeholder="Any additional suggestion"
              className="feedback-textarea"
              onChange={(e) => setAdditionalSuggestions(e.target.value)}
            ></textarea>

            <div className="feedback-detail-section">
              <h5>What kind of trip was it?</h5>
              {["Business", "Vacation"].map((type, index) => (
                <button
                  key={index}
                  className={selectedTripType === type ? "selected" : ""}
                  onClick={() => handleSingleSelect(setSelectedTripType, type)}
                >
                  {type}
                </button>
              ))}

              <h5>Who did you travel with?</h5>
              {["Family", "Friends", "Couple", "Solo"].map(
                (companion, index) => (
                  <button
                    key={index}
                    className={
                      selectedCompanion === companion ? "selected" : ""
                    }
                    onClick={() =>
                      handleSingleSelect(setSelectedCompanion, companion)
                    }
                  >
                    {companion}
                  </button>
                )
              )}

              <h5>How would you describe the hotel?</h5>
              {[
                "Luxury",
                "Prime location",
                "Cleanliness",
                "Budget friendly",
                "Family friendly",
              ].map((desc, index) => (
                <button
                  key={index}
                  className={
                    selectedHotelDescription.includes(desc) ? "selected" : ""
                  }
                  onClick={() => handleMultiSelect(desc)}
                >
                  {desc}
                </button>
              ))}
            </div>

            <div className="submit-btn-container">
              <button className="submit-btn" onClick={() => submitFeedback()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
